/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, type ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import { LoadingButton } from "../../components/LoadingButton/LoadingButton";
import { ResizablePanel } from "../../components/ResizablePanel/ResizablePanel";
import { ControlledJsonEditor } from "../../components/HookForms/ControlledJsonEditor";
import { UpdateErrorFields, loadableUpdateErrorState, updateErrorFields } from "../../services/updateErrorApi";
import { Operation } from "../../services/Operation";
import { objectIsEmpty } from "../../helpers/object";
import { ExecutionCountResponseTable } from "./ExecutionCountResponseTable";
import Error from "@mui/icons-material/Error";
import { accepts } from "mongodb-language-model";
import { visuallyHidden } from "@mui/utils";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

/**
 * Update error view component to demonstrate a page
 * @returns Update error view component
 */
export function UpdateErrorView(): ReactElement {
  const [result] = useAtom(loadableUpdateErrorState);
  const dispatch = useSetAtom(updateErrorFields);
  const [open, setOpen] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    formState: { isDirty, isValid, errors },
  } = useForm<UpdateErrorFields>({
    mode: "onChange",
  });

  /** Mongo validation function */
  const validateMongo = (data: string) => {
    const validMongo = accepts(data);
    return validMongo ? true : "Invalid mongo query";
  };

  return (
    <Container maxWidth="md">
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete errors</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently DELETE this customer data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            Do not delete
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleSubmit((data) => dispatch({ ...data, operation: Operation.DELETE }))();
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h4" gutterBottom>
        Reprocess Business Errors
      </Typography>

      <Box component="form" noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        {/* The rich JSON editor is likely not ideal for screen reader usage and testing, so it's hidden and a text input is provided */}
        <ResizablePanel initialSize={300} aria-hidden="true">
          <ControlledJsonEditor
            control={control}
            clearErrors={clearErrors}
            name="filter"
            rules={{
              validate: validateMongo,
            }}
          />
        </ResizablePanel>

        {/* This is a simple text input for screen readers */}
        <Box sx={visuallyHidden}>
          <TextField
            {...register("filter", {
              validate: validateMongo,
            })}
            label="Filter"
            aria-errormessage="filter-error"
            required
          />
        </Box>

        <Typography id="filter-error" color="error">
          {errors?.filter?.message}
        </Typography>

        <Box display="flex" marginLeft="auto" marginTop={2}>
          <LoadingButton
            state={result.state}
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(true);
            }}
            disabled={!isDirty || !isValid || !objectIsEmpty(errors)}
          >
            Clear Errors
          </LoadingButton>

          <LoadingButton
            state={result.state}
            sx={{ ml: 2 }}
            variant="contained"
            disabled={!isDirty || !isValid || !objectIsEmpty(errors)}
            onClick={handleSubmit((data) => dispatch({ ...data, operation: Operation.UPDATE }))}
          >
            Update Errors
          </LoadingButton>
        </Box>
      </Box>

      {result.state === "hasData" && result.data ? (
        <ExecutionCountResponseTable data={result.data} />
      ) : result.state === "hasError" ? (
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Error color="error" sx={{ pr: 1 }} />
            Failed to update errors
          </Box>
        </Box>
      ) : result.state === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
    </Container>
  );
}
