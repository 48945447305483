/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { AlertApi, AlertIngestRequest, AlertIngestRequestAlertMetadata } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { refreshAtom } from "../helpers/atoms";
import { handleError } from "./apiError";
import { accessTokenAtom } from "./userService";
import { enqueueSnackbar } from "notistack";
import { tryParseJSON } from "../helpers/tryParse";
import { MetadataInputMethod } from "./MetadataInputMethod";

/** The API */
const alertApi = new AlertApi(DEFAULT_OPTIONS, config.apiUri);

/** The ingest alert fields */
export type IngestAlertFields = {
  metadataInputMethod?: MetadataInputMethod;
  rawAlert: string;
  organisationId: string;
  agentId?: string;
  region?: string;
  alertMetadataJson?: string;
  alertMetadata?: {
    initialIngestionTimeMs?: string;
    retryCount?: number;
    sourceSystem?: string;
    sourceSystemAlias?: string;
    sourceSystemVersion?: string;
  };
  alertId?: string;
};

/** The request input fields */
export const ingestAlertFields = atom<null | IngestAlertFields>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the ingest alert API
 * @param [field, idToken]
 */
const ingestAlertState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(ingestAlertFields);

  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      let alertMetadata: AlertIngestRequestAlertMetadata = {};

      if (fields.metadataInputMethod === MetadataInputMethod.FORM) {
        // Convert date time picker value into ms
        let initialIngestionTimeMsProcessed;
        if (fields.alertMetadata?.initialIngestionTimeMs !== undefined) {
          initialIngestionTimeMsProcessed = new Date(fields.alertMetadata.initialIngestionTimeMs).getTime();
        }

        alertMetadata = { ...fields.alertMetadata, initialIngestionTimeMs: initialIngestionTimeMsProcessed };
      } else if (fields.alertMetadataJson !== undefined) {
        alertMetadata = tryParseJSON(fields.alertMetadataJson) || {};
      }

      const ingestAlertRequest: AlertIngestRequest = {
        rawAlert: fields.rawAlert,
        organisationId: fields.organisationId,
        alertId: fields.alertId,
        agentId: fields.agentId,
        alertMetadata,
      };

      const { data } = await alertApi.alertIngest(fields.region, ingestAlertRequest, {
        headers: { Authorization: accessToken },
      });

      /*
       * Admin API nests failured messages on a per region basis, however this UI is currently only set up to handle one region.
       * This checks if the region we have perfomed the rquest in failed, and throws accordingly
       */
      if (data !== undefined && Object.values(data)[0]?.errorMessage) {
        throw new Error(Object.values(data)[0].errorMessage);
      }

      enqueueSnackbar("Alert succesfully ingested.", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });

      return Object.values(data)[0];
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/** Loadable atom */
export const loadableIngestAlertState = loadable(ingestAlertState);
