/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { ErrorApi } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { refreshAtom } from "../helpers/atoms";
import { handleError } from "./apiError";
import { Operation } from "./Operation";
import { accessTokenAtom } from "./userService";

/** The API */
const errorApi = new ErrorApi(DEFAULT_OPTIONS, config.apiUri);

/** The update error fields */
export type UpdateErrorFields = { filter: string };

/** The request input fields */
export const updateErrorFields = atom<null | (UpdateErrorFields & { operation: Operation })>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the update error API
 * @param [field, idToken]
 */
const updateErrorState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(updateErrorFields);
  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      switch (fields.operation) {
        case Operation.DELETE: {
          const { data } = await errorApi.errorDelete(
            undefined,
            { filter: fields.filter },
            { headers: { Authorization: accessToken } },
          );
          return data;
        }
        case Operation.UPDATE: {
          const { data } = await errorApi.errorUpdate(
            undefined,
            { filter: fields.filter },
            { headers: { Authorization: accessToken } },
          );
          return data;
        }
      }
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/** Loadable atom */
export const loadableUpdateErrorState = loadable(updateErrorState);
